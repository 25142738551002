import React from "react"
import { graphql } from "gatsby"
import { Layout, PostPreview, ArchivePagination, Seo } from "../components"


const Archive = (props) => {
  const {
    data: {
      allWpPost: { nodes, pageInfo },
    },
    pageContext: { schema },
    pageContext: { archivePath },
  } = props

  const { pageContext } = props

  let pageData = {
    title: null, featuredImage: null, uri: null, author: null, modified: null, date: null, commentCount: null, pageNumber: pageContext.pageNumber
  }

  const { head } = schema
  const name = ((props?.pageResources?.json?.data?.allWpPost?.nodes || [])?.[0]?.categories?.nodes || [])?.[0]?.name || '';

  return (
    <Layout isArchive={true}>
      <Seo head={head} pageData={pageData}/>
      <h1 style={{ display: 'flex', justifyContent: 'center' }}>Tramites y requisitos de { name }</h1>
      {nodes.map((post, index) => {
        return (
          <PostPreview
            key={index}
            index={index}
            post={post}
          />
        )
      })}
      <ArchivePagination {...pageInfo} archivePath={archivePath} />
    </Layout>
  )
}

export const query = graphql`
  query ArchivePage(
    $offset: Int!
    $perPage: Int!
    $userDatabaseId: Int
    $categoryDatabaseId: Int
  ) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: {
        author: {
          node: { databaseId: { eq: $userDatabaseId } }}
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostPreviewContent
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
  }
`

export default Archive